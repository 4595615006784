
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

interface RadioOption {
  text: string;
  value: string;
  id: string;
  navigate: string;
}

@Component({})
  export default class Radio extends Vue {

    // Data
    private isSelected: boolean = false;
    private activeId: string = '0';
    private activeStyle: object = {};
    private resizing: boolean = false;
    private buttonDirection: string = 'row';


    // Props
    @Prop({default: []}) private options!: RadioOption[];
    @Prop({default: null}) private value!: string;
    @Prop({default: 'id'}) private id!: string;
    @Prop({default: null}) private label!: string;
    @Prop({default: false}) private forceColumn!: boolean;

    @Watch('forceColumn')
    private forceColumnChange(newVal: string, oldVal: string) {
      if (newVal) this.buttonDirection = 'column';
    }

    private mounted() {
      this.$nextTick(function() {
        if (this.forceColumn) {
          this.buttonDirection = 'column';
        }
        window.addEventListener('resize', this.resizeEvents);
        if (this.value !== null && this.value !== undefined && this.options.length > 0) {
          this.activeId = this.options.filter((option) => option.value === this.value)[0].id;
          this.isSelected = true;
          this.resizeEvents();
        }
        this.checkForTextOverflow();
      });
    }

    private beforeDestroy() {
      window.removeEventListener('resize', this.resizeEvents);
    }

    private checkForTextOverflow() {
      if (!this.forceColumn) {

        const changeToNumber = (value: string | null) => {
          if (value) {
            return Number(value.replace(/[a-z]/g, ''));
          } else {
            return 0;
          }
        };

        const container = (this.$refs['options_' + this.id] as HTMLElement[])[0];
        if (container) {
          const containerLength = container.getBoundingClientRect().width
            + (changeToNumber(window.getComputedStyle(container).marginLeft) * 2);
          let textLength = 0;
          let i = 0;
          for (const containerButton of container.children) {
            if (i !== 0) {
              textLength += changeToNumber(window.getComputedStyle(containerButton.children[0]).width) + 75;
            }
            i++;
          }
          const button = (this.$refs['option_' + (this.options[0].id)] as HTMLElement[])[0].children[0];
          const buttonsLength = changeToNumber(window.getComputedStyle(button).width);
          const flexDirection = window.getComputedStyle(container).flexDirection;
          if ((containerLength < (textLength))) {
            this.buttonDirection = 'column';
          } else {
            this.buttonDirection = 'row';
          }
        }
      }
    }

    private setOption(option: RadioOption) {
      this.isSelected = true;
      this.activeId = option.id;
      this.setActive(option.id);
      this.$emit('input', option.value);
      this.$emit('option', option);
    }

    private navigateByClick(option: RadioOption) {
      if (option.navigate) {  this.$emit('click', option.navigate); }
    }

    private setActive(id: string) {
      const target = document.querySelector('#' + id) as HTMLElement;
      this.activeStyle = {
        transform: `translate(${target.offsetLeft}px, ${target.offsetTop}px)`,
        width: target.getBoundingClientRect().width + 'px',
        height: target.getBoundingClientRect().height + 'px',
      };
    }

    private resizeEvents() {
      this.resizing = true;
      this.checkForTextOverflow();
      if (this.value !== null && this.value !== undefined) {
        this.setActive(this.activeId);
      } else {
        this.setActive(this.options[0].id);
      }

      setTimeout(() => {
        this.resizing = false;
        if (this.value !== null && this.value !== undefined) {
          this.setActive(this.activeId);
        } else {
          this.setActive(this.options[0].id);
        }
        this.checkForTextOverflow();
      }, 100);
    }
  }
