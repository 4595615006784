
import { mapGetters } from 'vuex';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class TotalsContainer extends Vue {
  @Prop() private header!: string;
  @Prop({ default: '$0' }) private total!: string;
  @Prop({ default: false }) private visible!: boolean;
  @Prop({ default: '' }) private hiddenHeader!: string;
  @Prop({ default: '' }) private hiddenCopy!: string;
}
