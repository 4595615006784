
import { mapGetters } from 'vuex';
import { Component, Prop, Vue } from 'vue-property-decorator';
import DottedLine from './DottedLine.vue';

@Component({
  components: {
    DottedLine,
  },
})
export default class ThreeColumnListItem extends Vue {
  @Prop() private heading!: string;
}
