
import { mapGetters } from 'vuex';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  // TODO: remove if unused
  computed: {
    ...mapGetters({
      user: 'getUser',
      product: 'getProduct',
    }),
  },
})
export default class TotalsLineItem extends Vue {

  @Prop({default: false}) private isHeading!: boolean;
  @Prop({default: false}) private firstColumnHeading!: boolean;
  @Prop({default: ['$0']}) private values!: string[];
}
