
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class SubmitButton extends Vue {

  private inheritAttrs: boolean = false;

  @Prop({default: false}) private showLoading!: boolean;
  @Prop({default: 'Next'}) private text!: string;
  @Prop({default: true}) private showIcon!: boolean;
  @Prop({default: 'arrow_forward'}) private icon!: string;
  @Prop({default: false}) private disabled!: boolean;
  @Prop({default: ''}) private path!: string;

}
