
import { mapGetters } from 'vuex';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AppHeaderImage extends Vue {
  @Prop() private image!: string;
  @Prop() private alt!: string;
  @Prop() private content!: string;
}
