
import { mapGetters } from 'vuex';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Product from '../interfaces/product';
import AppHeader from '../components/content/AppHeader.vue';
import AppHeaderImage from '../components/content/AppHeaderImage.vue';
import AppCtaButton from '../components/content/AppCtaButton.vue';
import SectionHeader from '../components/content/SectionHeader.vue';
import ThreeColumnList from '../components/content/ThreeColumnList.vue';
import ThreeColumnListItem from '../components/content/ThreeColumnListItem.vue';

@Component({
  components: {
    AppHeader,
    AppHeaderImage,
    AppCtaButton,
    SectionHeader,
    ThreeColumnList,
    ThreeColumnListItem,
  },
  computed: {
    ...mapGetters([
      'getProduct',
    ]),
  },
})
export default class ProductLanding extends Vue {
  private pageHeading: string = 'Leaving a';
  private pageHeadingRed: string = 'lasting gift.';
  private pageSubheading: string = '';
  private headerContent: string = 'You’ve worked hard over your lifetime to achieve financial stability. But financing longevity with market uncertainty seems harder and harder to do. Let alone passing some of that stability on to your loved ones conveniently. Single Premium Whole Life Insurance (SPWL) from Assurity provides new tools and new norms you should expect for your financial plan.';
  private headerImage: string = '/images/landing-photos/grandparents-grandaughter-laughing.jpg';
  private headerImageAlt: string = '.';
  private ctaLink: string = '/quote';
  private ctaLinkText: string = 'Learn More';
}
