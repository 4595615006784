
import { mapGetters } from 'vuex';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AppHeader extends Vue {
  @Prop() private subheading!: string;
  @Prop() private heading!: string;
  @Prop() private headingRed!: string;
}
