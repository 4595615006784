
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Tooltip from './Tooltip.vue';


@Component({
  components: {
    Input,
    Tooltip,
  },
})
export default class Input extends Vue {
  [key: string]: any;

  // Data
  private inheritAttrs: boolean = false;
  private mutatedValue: string = '';

  // Props
  @Prop() private label!: string;
  @Prop() private id!: string;
  @Prop() private grouplabel!: string;
  @Prop() private value!: string;
  @Prop() private required!: boolean;
  @Prop() private optional!: boolean;
  @Prop() private error!: string;
  @Prop() private tooltip!: string;
  @Prop() private labelbuffer!: boolean;
  @Prop() private format!: string;
  @Prop() private showLoading!: boolean;
  @Prop() private autocomplete!: string;
  @Prop() private minlength!: number;
  @Prop() private maxlength!: number;
  @Prop() private min!: number;
  @Prop() private max!: number;
  @Prop({default: false}) private acceptDecimals!: boolean;

  private created() {
    this.formatValue({ keyCode: 5 } as KeyboardEvent, this.value);
  }

  @Watch('value')
  private onValueChanged(value: string, oldValue: string) {
    this.formatValue(event as KeyboardEvent, value);
  }

  @Watch('mutatedValue')
  private onMutatedValueChanged(value: string, oldValue: string) {
    if (this.value) {
      switch (this.format) {
        case 'currency':
        case 'phone':
        case 'ssn':
          if (this.mutatedValue) {
            this.$emit('input', String(this.mutatedValue).replace(/[^0-9.]/g, ''));
          } else {
            this.$emit('input', this.value);
          }
          break;
        case 'greenCardNumber':
          if (this.mutatedValue) {
            this.$emit('input', String(this.mutatedValue).replace(/[ ]/g, ''));
          } else {
            this.$emit('input', this.value);
          }
          break;
          case 'email':
              if (this.mutatedValue) {
                  this.isValidEmail(this.mutatedValue);
              }
              break;
        case 'number':
          this.$emit('input', this.value);
          break;
        default:
          this.$emit('input', this.value);
          break;
      }
    }
  }

  private limitInputs(event: KeyboardEvent) {
    this.isNumber(event);
    this.isBelowTwelveInput(event);
    this.isGreenCard(event);
  }

  private incrementNumber(event: Event) {
    if (
      (this.format === 'inches' || this.format === 'months') &&
      Number((event.target as HTMLInputElement).value) > 10
    ) {
      return event.preventDefault();
    }
    if ((event.target as HTMLInputElement).type.toLowerCase() === 'number') {
      event.preventDefault();
      this.$emit('input', Number(this.mutatedValue) + 1);
    }
  }

  private decrementNumber(event: KeyboardEvent) {
    if ((event.target as HTMLInputElement).type.toLowerCase() === 'number') {
      event.preventDefault();
      if (Number(this.mutatedValue) > 0) {
        this.$emit('input', Number(this.mutatedValue) - 1);
      } else {
        return event.preventDefault();
      }
    }
  }

  private setInputRange(attr: string) {
    if (this.format === 'ssn') {
        return 11;
    } else
    if (this.format === 'phone') {
      return 14;
    } else
    if (this[attr] !== null) {
      return this[attr];
    }
  }

  private formatValue(event: KeyboardEvent, value: string) {
    switch (this.format) {
      case 'currency':
        this.formatAsCurrency(event, value);
        break;
      case 'phone':
        this.formatAsPhone(event, value);
        break;
      case 'ssn':
        this.formatAsSSN(event, value);
        break;
      case 'greenCardNumber':
        this.formatAsGreenCard(event, value);
        break;
      default:
        this.mutatedValue = value;
        this.$emit('input', value);
    }
  }

  private formatAsCurrency(event: KeyboardEvent, value: string) {
    if (value) {
      this.mutatedValue = Number(String(value)
      .replace(/[^0-9.]/g, ''))
      .toLocaleString(
        'en-US',
        {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
        },
      );
    } else {
      this.mutatedValue = '';
    }
    this.$emit('input', Number(String(value).replace(/[^0-9.]/g, '')));
  }

  private formatAsPhone(event: KeyboardEvent, value: string) {
    const target = event.target as HTMLInputElement;
    if (value !== null) {
      const phone = String(value).replace(/[- )(]/g, '');
      if (event && event.keyCode !== 8 && value) {
        if (phone.length < 3) {
            this.mutatedValue = '('
            + phone;
        } else if (phone.length === 3 ) {
            this.mutatedValue = '('
            + phone
            .substring(0, 3)
            + ') ';
        } else if (phone.length > 4 && phone.length < 6) {
            this.mutatedValue = '('
            + phone
            .substring(0, 3)
            + ') '
            + phone
            .substring(3, 6);
        } else if (phone.length === 6) {
            this.mutatedValue = '('
            + phone
            .substring(0, 3)
            + ') '
            + phone
            .substring(3, 6)
            + '-';
        } else if (phone.length > 5) {
            this.mutatedValue = '('
            + phone
            .substring(0, 3)
            + ') '
            + phone
            .substring(3, 6)
            + '-'
            + phone
            .substring(6, phone.length);
        }
      } else {
        if (this.mutatedValue && event) {
          this.mutatedValue = target.value;
          const lastSelectionChar = this.mutatedValue
          .substring(target.selectionStart! - 1, target.selectionStart!);
          const lastTwoSelectionChar = this.mutatedValue
          .substring(target.selectionStart! - 2, target.selectionStart!);

          if ( lastSelectionChar === '-' || lastSelectionChar === '(' || lastSelectionChar === ')') {
            this.mutatedValue = this.mutatedValue.slice(0, target.selectionStart! - 1);
          }

          if (lastTwoSelectionChar === ') ') {
            this.mutatedValue = this.mutatedValue.slice(0, target.selectionStart! - 2);
          }
        }
      }
    } else {
      this.mutatedValue = '';
    }
    this.$emit('input', this.mutatedValue ? String(this.mutatedValue).replace(/[^0-9.]/g, '') : this.mutatedValue);
  }

  private formatAsSSN(event: KeyboardEvent, value: string) {
    if (value !== null) {
      if (event && event.keyCode !== 8 && value) {
        const ss = String(value).replace(/[- )(]/g, '');
        if (ss.length < 3) {
            this.mutatedValue = ss;
        } else if (ss.length === 3 ) {
            this.mutatedValue = ss
              .substring(0, 3)
              + '-';
        } else if (ss.length > 3 && ss.length < 5) {
            this.mutatedValue = ss
              .substring(0, 3)
              + '-'
              + ss
              .substring(3, 5);
        } else if (ss.length === 5) {
            this.mutatedValue = ss
              .substring(0, 3)
              + '-'
              + ss
              .substring(3, 5)
              + '-';
        } else if (ss.length > 4) {
            this.mutatedValue = ss
              .substring(0, 3)
              + '-'
              + ss
              .substring(3, 5)
              + '-'
              + ss
              .substring(5, ss.length);
        }
      } else {
        if (this.mutatedValue && event && (event.target)) {
          const target = event.target as HTMLInputElement;
          this.mutatedValue = target.value;
          const lastSelectionChar = this.mutatedValue
            .substring(
              target.selectionStart! - 1,
              target.selectionStart!);
          const lastTwoSelectionChar = this.mutatedValue
            .substring(
              target.selectionStart! - 2,
              target.selectionStart!);

          if (lastSelectionChar === '-') {
            this.mutatedValue = this.mutatedValue.slice(0, target.selectionStart! - 1);
          }
        }
      }
    } else {
      this.mutatedValue = '';
    }
    this.$emit('input', this.mutatedValue ? String(this.mutatedValue).replace(/[^0-9.]/g, '') : this.mutatedValue);
  }

  private formatAsGreenCard(event: KeyboardEvent, value: string) {
    if (value) {
      if (event && event.keyCode !== 8 && value) {
        const greencard = String(value).replace(/[- )(]/g, '');
        if (greencard.length < 3) {
          this.mutatedValue = greencard;
        } else if (greencard.length === 3 ) {
            this.mutatedValue = greencard
            .substring(0, 3) + '-';
        } else if (greencard.length > 3 && greencard.length < 6) {
            this.mutatedValue = greencard
            .substring(0, 3)
            + '-'
            + greencard
            .substring(3, 6);
        } else if (greencard.length === 6) {
            this.mutatedValue = greencard
            .substring(0, 3)
            + '-'
            + greencard
            .substring(3, 6) + '-';
        } else if (greencard.length > 6) {
            this.mutatedValue = greencard
            .substring(0, 3)
            + '-'
            + greencard
            .substring(3, 6)
            + '-'
            + greencard
            .substring(6, greencard.length);
        }
      } else {
        if (this.mutatedValue && event) {
          const target = (event.target as HTMLInputElement);
          this.mutatedValue = target.value;
          const lastSelectionChar = this.mutatedValue
          .substring(target.selectionStart! - 1, target.selectionStart!);
          const lastTwoSelectionChar = this.mutatedValue
          .substring(target.selectionStart! - 2, target.selectionStart!);

          if (lastSelectionChar === ' ') {
            this.mutatedValue = this.mutatedValue.slice(0, target.selectionStart! - 1);
          }
        }
      }
    } else {
      this.mutatedValue = '';
    }
    this
      .$emit('input',
        this.mutatedValue
        ? String(this.mutatedValue)
        .replace(/[- )(]/g, '')
        .toUpperCase()
        : this.mutatedValue
        .toUpperCase(),
      );
  }

  private isNumber(event: KeyboardEvent, bypassFormat?: boolean) {
    if (
      this.format === 'currency' ||
      this.format === 'ssn' ||
      this.format === 'phone' ||
      this.format === 'number' ||
      (event.target as HTMLInputElement).type.toLowerCase() === 'number' ||
      bypassFormat
    ) {
      const keyOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 'Backspace', 'Delete', 'Tab', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];
      if (this.acceptDecimals) {
        if ((event.target as HTMLInputElement).value.indexOf('.') === -1) {
          // only allow one decimal point
          keyOptions.push('.');
        }
      }
      const isAllowed = keyOptions.filter((key) => String(key) === event.key);

      return isAllowed.length > 0 ? true : event.preventDefault();
    } else {
      return true;
    }
  }

  private isLetter(event: KeyboardEvent, bypassFormat?: boolean) {
    const letters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    const isAllowed = letters.includes(event.key);
    return isAllowed ? true : event.preventDefault();
  }

  private isGreenCard(event: KeyboardEvent) {
    if (this.format === 'greenCardNumber') {
      this.isNumber(event, true);
    }
  }

  private isBelowTwelveInput(event: KeyboardEvent) {
    if (this.format === 'inches' || this.format === 'months') {
      let keyOptions: any[] = ['Backspace', 'Delete', 'Tab', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];
      if (!(event.target as HTMLInputElement).value) {
        keyOptions = [...keyOptions, ...[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]];
      } else
      if ((event.target as HTMLInputElement).value &&
        String((event.target as HTMLInputElement).value).length > 0 &&
        String((event.target as HTMLInputElement).value).charAt(0) === '1'
      ) {
        keyOptions = [...keyOptions, ...[0, 1]];
      }
      const isAllowed = keyOptions.filter((key) => String(key) === event.key);
      if (isAllowed.length > 0 &&
        (
          (String((event.target as HTMLInputElement).value).length < 2 &&
          Number((event.target as HTMLInputElement).value) < 11) ||
          (String((event.target as HTMLInputElement).value).length < 1 &&
          Number((event.target as HTMLInputElement).value) === 1)
        )
      ) {
        return true;
      } else {
        event.preventDefault();
      }
    } else {
      return true;
    }
  }

  private isValidEmail(email: string) {
    const emailRegex = new RegExp('[^@]+@[^\\.]+\\..+');
    const isValid = emailRegex.test(email);
    isValid ? this.error = '' : this.error = 'Please enter a valid e-mail address.';
  }

}
