
import { mapGetters } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';
import User from '../interfaces/user';
import Product from '../interfaces/product';
import Input from '../components/forms/Input.vue';
import Radio from '../components/forms/Radio.vue';
import SubmitButton from '../components/forms/SubmitButton.vue';
import FormContainer from '../components/forms/FormContainer.vue';
import TotalsContainer from '../components/forms/TotalsContainer.vue';
import TotalsLineItem from '../components/forms/TotalsLineItem.vue';

@Component({
  components: {
    Input,
    Radio,
    SubmitButton,
    FormContainer,
    TotalsContainer,
    TotalsLineItem,
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      product: 'getProduct',
    }),
  },
})
export default class SinglePremiumWholeLife extends Vue {
  private showAdvancedOptions: boolean = false;
  private user!: User;
  private product!: Product;

  // Premium per unit is the associated number in this data out of 1 unit (1000 is a unit in this case)
  private premiumPerUnit: any = {
    age: {
      0: {
        female: {
          nonTobaccoBase: 87.63,
          tobaccoBase: null,
          juvenileCash: 56.48572,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
        male: {
          nonTobaccoBase: 99.74,
          tobaccoBase: null,
          juvenileCash: 66.14396,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
      },
      1: {
        female: {
          nonTobaccoBase: 90,
          tobaccoBase: null,
          juvenileCash: 58.35426,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
        male: {
          nonTobaccoBase: 102.61,
          tobaccoBase: null,
          juvenileCash: 68.39631,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
      },
      2: {
        female: {
          nonTobaccoBase: 92.6,
          tobaccoBase: null,
          juvenileCash: 60.40839,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
        male: {
          nonTobaccoBase: 105.67,
          tobaccoBase: null,
          juvenileCash: 70.81899,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
      },
      3: {
        female: {
          nonTobaccoBase: 95.35,
          tobaccoBase: null,
          juvenileCash: 62.58766,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
        male: {
          nonTobaccoBase: 108.86,
          tobaccoBase: null,
          juvenileCash: 73.35182,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
      },
      4: {
        female: {
          nonTobaccoBase: 98.21,
          tobaccoBase: null,
          juvenileCash: 64.8584,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
        male: {
          nonTobaccoBase: 112.17,
          tobaccoBase: null,
          juvenileCash: 75.9894,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
      },
      5: {
        female: {
          nonTobaccoBase: 101.17,
          tobaccoBase: null,
          juvenileCash: 67.21448,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
        male: {
          nonTobaccoBase: 115.61,
          tobaccoBase: null,
          juvenileCash: 78.73561,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
      },
      6: {
        female: {
          nonTobaccoBase: 104.25,
          tobaccoBase: null,
          juvenileCash: 69.6496,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
        male: {
          nonTobaccoBase: 119.17,
          tobaccoBase: null,
          juvenileCash: 81.59449,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
      },
      7: {
        female: {
          nonTobaccoBase: 107.43,
          tobaccoBase: null,
          juvenileCash: 72.17628,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
        male: {
          nonTobaccoBase: 122.88,
          tobaccoBase: null,
          juvenileCash: 84.56087,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
      },
      8: {
        female: {
          nonTobaccoBase: 110.73,
          tobaccoBase: null,
          juvenileCash: 74.80739,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
        male: {
          nonTobaccoBase: 126.72,
          tobaccoBase: null,
          juvenileCash: 87.64812,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
      },
      9: {
        female: {
          nonTobaccoBase: 114.14,
          tobaccoBase: null,
          juvenileCash: 77.53738,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
        male: {
          nonTobaccoBase: 130.7,
          tobaccoBase: null,
          juvenileCash: 90.85143,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
      },
      10: {
        female: {
          nonTobaccoBase: 117.69,
          tobaccoBase: null,
          juvenileCash: 80.36997,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
        male: {
          nonTobaccoBase: 134.86,
          tobaccoBase: null,
          juvenileCash: 94.17515,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
      },
      11: {
        female: {
          nonTobaccoBase: 121.35,
          tobaccoBase: null,
          juvenileCash: 83.29967,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
        male: {
          nonTobaccoBase: 139.15,
          tobaccoBase: null,
          juvenileCash: 97.61462,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
      },
      12: {
        female: {
          nonTobaccoBase: 125.14,
          tobaccoBase: null,
          juvenileCash: 86.33949,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
        male: {
          nonTobaccoBase: 143.59,
          tobaccoBase: null,
          juvenileCash: 101.17425,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
      },
      13: {
        female: {
          nonTobaccoBase: 129.09,
          tobaccoBase: null,
          juvenileCash: 89.5029,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
        male: {
          nonTobaccoBase: 148.15,
          tobaccoBase: null,
          juvenileCash: 104.84035,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
      },
      14: {
        female: {
          nonTobaccoBase: 133.19,
          tobaccoBase: null,
          juvenileCash: 92.77592,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
        male: {
          nonTobaccoBase: 152.84,
          tobaccoBase: null,
          juvenileCash: 108.58985,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
      },
      15: {
        female: {
          nonTobaccoBase: 137.37,
          tobaccoBase: null,
          juvenileCash: 96.11665,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
        male: {
          nonTobaccoBase: 157.61,
          tobaccoBase: null,
          juvenileCash: 112.39916,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
      },
      16: {
        female: {
          nonTobaccoBase: 141.63,
          tobaccoBase: null,
          juvenileCash: 99.50962,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
        male: {
          nonTobaccoBase: 162.42,
          tobaccoBase: null,
          juvenileCash: 116.23512,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
      },
      17: {
        female: {
          nonTobaccoBase: 145.96,
          tobaccoBase: null,
          juvenileCash: 102.96653,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
        male: {
          nonTobaccoBase: 167.27,
          tobaccoBase: null,
          juvenileCash: 120.06378,
          nonTobaccoCash: null,
          tobaccoCash: null,
        },
      },
      18: {
        female: {
          nonTobaccoBase: 139.01,
          tobaccoBase: 175.6,
          juvenileCash: 106.52678,
          nonTobaccoCash: 97.16577,
          tobaccoCash: 125.63188,
        },
        male: {
          nonTobaccoBase: 158.12,
          tobaccoBase: 196,
          juvenileCash: 123.86826,
          nonTobaccoCash: 112.8204,
          tobaccoCash: 142.37495,
        },
      },
      19: {
        female: {
          nonTobaccoBase: 142.89,
          tobaccoBase: 180.98,
          juvenileCash: 110.22176,
          nonTobaccoCash: 100.5065,
          tobaccoCash: 130.04984,
        },
        male: {
          nonTobaccoBase: 161.83,
          tobaccoBase: 201.1,
          juvenileCash: 127.80065,
          nonTobaccoCash: 116.32932,
          tobaccoCash: 147.01671,
        },
      },
      20: {
        female: {
          nonTobaccoBase: 146.86,
          tobaccoBase: 186.54,
          juvenileCash: 114.05656,
          nonTobaccoCash: 103.97366,
          tobaccoCash: 134.63499,
        },
        male: {
          nonTobaccoBase: 165.69,
          tobaccoBase: 206.46,
          juvenileCash: 131.84827,
          nonTobaccoCash: 119.94576,
          tobaccoCash: 151.8017,
        },
      },
      21: {
        female: {
          nonTobaccoBase: 151.01,
          tobaccoBase: 192.35,
          juvenileCash: 118.0455,
          nonTobaccoCash: 107.58113,
          tobaccoCash: 139.40245,
        },
        male: {
          nonTobaccoBase: 169.73,
          tobaccoBase: 212.08,
          juvenileCash: 136.02472,
          nonTobaccoCash: 123.68301,
          tobaccoCash: 156.74442,
        },
      },
      22: {
        female: {
          nonTobaccoBase: 155.38,
          tobaccoBase: 198.45,
          juvenileCash: 122.18534,
          nonTobaccoCash: 111.32508,
          tobaccoCash: 144.35027,
        },
        male: {
          nonTobaccoBase: 174.05,
          tobaccoBase: 218.01,
          juvenileCash: 140.33517,
          nonTobaccoCash: 127.56371,
          tobaccoCash: 161.85123,
        },
      },
      23: {
        female: {
          nonTobaccoBase: 159.95,
          tobaccoBase: 204.83,
          juvenileCash: 126.47288,
          nonTobaccoCash: 115.21068,
          tobaccoCash: 149.47658,
        },
        male: {
          nonTobaccoBase: 178.68,
          tobaccoBase: 224.24,
          juvenileCash: 144.78506,
          nonTobaccoCash: 131.61111,
          tobaccoCash: 167.12875,
        },
      },
      24: {
        female: {
          nonTobaccoBase: 164.69,
          tobaccoBase: 211.36,
          juvenileCash: 130.89603,
          nonTobaccoCash: 119.24327,
          tobaccoCash: 154.77097,
        },
        male: {
          nonTobaccoBase: 183.61,
          tobaccoBase: 230.83,
          juvenileCash: 149.38002,
          nonTobaccoCash: 135.82263,
          tobaccoCash: 172.58386,
        },
      },
      25: {
        female: {
          nonTobaccoBase: 169.69,
          tobaccoBase: 218.19,
          juvenileCash: 135.45135,
          nonTobaccoCash: 123.42843,
          tobaccoCash: 160.23151,
        },
        male: {
          nonTobaccoBase: 188.95,
          tobaccoBase: 237.71,
          juvenileCash: 154.11725,
          nonTobaccoCash: 140.19566,
          tobaccoCash: 178.21535,
        },
      },
      26: {
        female: {
          nonTobaccoBase: 174.91,
          tobaccoBase: 225.22,
          juvenileCash: 140.1794,
          nonTobaccoCash: 127.77192,
          tobaccoCash: 165.89909,
        },
        male: {
          nonTobaccoBase: 194.63,
          tobaccoBase: 245,
          juvenileCash: 159.02844,
          nonTobaccoCash: 144.76263,
          tobaccoCash: 184.0555,
        },
      },
      27: {
        female: {
          nonTobaccoBase: 180.35,
          tobaccoBase: 232.48,
          juvenileCash: 145.07796,
          nonTobaccoCash: 132.27974,
          tobaccoCash: 171.7731,
        },
        male: {
          nonTobaccoBase: 200.63,
          tobaccoBase: 252.64,
          juvenileCash: 164.12895,
          nonTobaccoCash: 149.6003,
          tobaccoCash: 190.12077,
        },
      },
      28: {
        female: {
          nonTobaccoBase: 185.98,
          tobaccoBase: 239.96,
          juvenileCash: 150.16231,
          nonTobaccoCash: 136.94044,
          tobaccoCash: 177.86987,
        },
        male: {
          nonTobaccoBase: 206.9,
          tobaccoBase: 260.73,
          juvenileCash: 169.43459,
          nonTobaccoCash: 154.67465,
          tobaccoCash: 196.4281,
        },
      },
      29: {
        female: {
          nonTobaccoBase: 191.8,
          tobaccoBase: 247.69,
          juvenileCash: 155.42223,
          nonTobaccoCash: 141.75127,
          tobaccoCash: 184.18118,
        },
        male: {
          nonTobaccoBase: 213.4,
          tobaccoBase: 269.22,
          juvenileCash: 174.96159,
          nonTobaccoCash: 159.95975,
          tobaccoCash: 202.99483,
        },
      },
      30: {
        female: {
          nonTobaccoBase: 197.85,
          tobaccoBase: 255.63,
          juvenileCash: 160.85597,
          nonTobaccoCash: 146.7269,
          tobaccoCash: 190.70714,
        },
        male: {
          nonTobaccoBase: 220.2,
          tobaccoBase: 278.07,
          juvenileCash: 180.70148,
          nonTobaccoCash: 165.48047,
          tobaccoCash: 209.81448,
        },
      },
      31: {
        female: {
          nonTobaccoBase: 204.08,
          tobaccoBase: 263.87,
          juvenileCash: 166.47052,
          nonTobaccoCash: 151.87378,
          tobaccoCash: 197.45627,
        },
        male: {
          nonTobaccoBase: 227.3,
          tobaccoBase: 287.26,
          juvenileCash: 186.64578,
          nonTobaccoCash: 171.22838,
          tobaccoCash: 216.88077,
        },
      },
      32: {
        female: {
          nonTobaccoBase: 210.59,
          tobaccoBase: 272.39,
          juvenileCash: 172.26459,
          nonTobaccoCash: 157.19864,
          tobaccoCash: 204.42935,
        },
        male: {
          nonTobaccoBase: 234.73,
          tobaccoBase: 296.95,
          juvenileCash: 192.77786,
          nonTobaccoCash: 177.16139,
          tobaccoCash: 224.17963,
        },
      },
      33: {
        female: {
          nonTobaccoBase: 217.33,
          tobaccoBase: 281.21,
          juvenileCash: 178.24548,
          nonTobaccoCash: 162.68272,
          tobaccoCash: 211.63547,
        },
        male: {
          nonTobaccoBase: 242.47,
          tobaccoBase: 307.02,
          juvenileCash: 199.08093,
          nonTobaccoCash: 183.28703,
          tobaccoCash: 231.69711,
        },
      },
      34: {
        female: {
          nonTobaccoBase: 224.26,
          tobaccoBase: 290.57,
          juvenileCash: 184.41248,
          nonTobaccoCash: 168.30714,
          tobaccoCash: 219.02806,
        },
        male: {
          nonTobaccoBase: 250.54,
          tobaccoBase: 317.39,
          juvenileCash: 205.56265,
          nonTobaccoCash: 189.59654,
          tobaccoCash: 239.44293,
        },
      },
      35: {
        female: {
          nonTobaccoBase: 231.46,
          tobaccoBase: 300.41,
          juvenileCash: 190.7567,
          nonTobaccoCash: 174.06969,
          tobaccoCash: 226.59213,
        },
        male: {
          nonTobaccoBase: 259,
          tobaccoBase: 328.06,
          juvenileCash: 212.22292,
          nonTobaccoCash: 196.05658,
          tobaccoCash: 247.41946,
        },
      },
      36: {
        female: {
          nonTobaccoBase: 238.95,
          tobaccoBase: 310.79,
          juvenileCash: 197.27763,
          nonTobaccoCash: 179.9767,
          tobaccoCash: 234.34427,
        },
        male: {
          nonTobaccoBase: 267.86,
          tobaccoBase: 339.02,
          juvenileCash: 219.05386,
          nonTobaccoCash: 202.658,
          tobaccoCash: 255.62186,
        },
      },
      37: {
        female: {
          nonTobaccoBase: 246.7,
          tobaccoBase: 321.41,
          juvenileCash: 203.97493,
          nonTobaccoCash: 186.02643,
          tobaccoCash: 242.26265,
        },
        male: {
          nonTobaccoBase: 276.93,
          tobaccoBase: 350.34,
          juvenileCash: 226.04779,
          nonTobaccoCash: 209.39989,
          tobaccoCash: 264.04559,
        },
      },
      38: {
        female: {
          nonTobaccoBase: 254.79,
          tobaccoBase: 332.25,
          juvenileCash: 210.8566,
          nonTobaccoCash: 192.24214,
          tobaccoCash: 250.34872,
        },
        male: {
          nonTobaccoBase: 286.21,
          tobaccoBase: 361.9,
          juvenileCash: 233.1972,
          nonTobaccoCash: 216.28958,
          tobaccoCash: 272.68665,
        },
      },
      39: {
        female: {
          nonTobaccoBase: 263.49,
          tobaccoBase: 343.67,
          juvenileCash: 217.931,
          nonTobaccoCash: 198.63943,
          tobaccoCash: 258.61957,
        },
        male: {
          nonTobaccoBase: 296.07,
          tobaccoBase: 374.07,
          juvenileCash: 240.51814,
          nonTobaccoCash: 223.35863,
          tobaccoCash: 281.53403,
        },
      },
      40: {
        female: {
          nonTobaccoBase: 272.19,
          tobaccoBase: 355.12,
          juvenileCash: 225.22272,
          nonTobaccoCash: 205.25876,
          tobaccoCash: 267.11539,
        },
        male: {
          nonTobaccoBase: 305.87,
          tobaccoBase: 386.13,
          juvenileCash: 248.01173,
          nonTobaccoCash: 230.63925,
          tobaccoCash: 290.548,
        },
      },
      41: {
        female: {
          nonTobaccoBase: 281.31,
          tobaccoBase: 366.83,
          juvenileCash: 232.74908,
          nonTobaccoCash: 212.12526,
          tobaccoCash: 275.85461,
        },
        male: {
          nonTobaccoBase: 315.96,
          tobaccoBase: 398.42,
          juvenileCash: 255.6871,
          nonTobaccoCash: 238.14871,
          tobaccoCash: 299.73257,
        },
      },
      42: {
        female: {
          nonTobaccoBase: 290.81,
          tobaccoBase: 378.9,
          juvenileCash: 240.52789,
          nonTobaccoCash: 219.2486,
          tobaccoCash: 284.86371,
        },
        male: {
          nonTobaccoBase: 326.33,
          tobaccoBase: 410.95,
          juvenileCash: 263.56134,
          nonTobaccoCash: 245.88153,
          tobaccoCash: 309.09225,
        },
      },
      43: {
        female: {
          nonTobaccoBase: 300.71,
          tobaccoBase: 391.32,
          juvenileCash: 248.57741,
          nonTobaccoCash: 226.64676,
          tobaccoCash: 294.14783,
        },
        male: {
          nonTobaccoBase: 336.98,
          tobaccoBase: 423.74,
          juvenileCash: 271.65964,
          nonTobaccoCash: 253.84018,
          tobaccoCash: 318.65326,
        },
      },
      44: {
        female: {
          nonTobaccoBase: 311.02,
          tobaccoBase: 404.12,
          juvenileCash: 256.90118,
          nonTobaccoCash: 234.33032,
          tobaccoCash: 303.71994,
        },
        male: {
          nonTobaccoBase: 347.94,
          tobaccoBase: 436.84,
          juvenileCash: 280.04471,
          nonTobaccoCash: 262.03513,
          tobaccoCash: 328.4494,
        },
      },
      45: {
        female: {
          nonTobaccoBase: 321.71,
          tobaccoBase: 417.31,
          juvenileCash: 265.51074,
          nonTobaccoCash: 242.30257,
          tobaccoCash: 313.5936,
        },
        male: {
          nonTobaccoBase: 359.34,
          tobaccoBase: 450.31,
          juvenileCash: 288.71426,
          nonTobaccoCash: 270.50732,
          tobaccoCash: 338.53552,
        },
      },
      46: {
        female: {
          nonTobaccoBase: 332.78,
          tobaccoBase: 430.84,
          juvenileCash: 274.39569,
          nonTobaccoCash: 250.56708,
          tobaccoCash: 323.74121,
        },
        male: {
          nonTobaccoBase: 371.07,
          tobaccoBase: 464.18,
          juvenileCash: 297.68823,
          nonTobaccoCash: 279.26874,
          tobaccoCash: 348.91336,
        },
      },
      47: {
        female: {
          nonTobaccoBase: 344.24,
          tobaccoBase: 444.72,
          juvenileCash: 283.54614,
          nonTobaccoCash: 259.1203,
          tobaccoCash: 334.14291,
        },
        male: {
          nonTobaccoBase: 383.19,
          tobaccoBase: 478.41,
          juvenileCash: 306.97275,
          nonTobaccoCash: 288.32452,
          tobaccoCash: 359.57227,
        },
      },
      48: {
        female: {
          nonTobaccoBase: 356.14,
          tobaccoBase: 458.91,
          juvenileCash: 292.96725,
          nonTobaccoCash: 267.95901,
          tobaccoCash: 344.79324,
        },
        male: {
          nonTobaccoBase: 395.73,
          tobaccoBase: 493.01,
          juvenileCash: 316.58167,
          nonTobaccoCash: 297.68759,
          tobaccoCash: 370.51559,
        },
      },
      49: {
        female: {
          nonTobaccoBase: 368.7,
          tobaccoBase: 473.74,
          juvenileCash: 302.66476,
          nonTobaccoCash: 277.08795,
          tobaccoCash: 355.6944,
        },
        male: {
          nonTobaccoBase: 409,
          tobaccoBase: 508.3,
          juvenileCash: 326.53638,
          nonTobaccoCash: 307.35718,
          tobaccoCash: 381.74133,
        },
      },
      50: {
        female: {
          nonTobaccoBase: 381.29,
          tobaccoBase: 488.57,
          juvenileCash: 312.64508,
          nonTobaccoCash: 286.505,
          tobaccoCash: 366.8429,
        },
        male: {
          nonTobaccoBase: 422.35,
          tobaccoBase: 523.62,
          juvenileCash: 336.82468,
          nonTobaccoCash: 317.34024,
          tobaccoCash: 393.24866,
        },
      },
      51: {
        female: {
          nonTobaccoBase: 394.26,
          tobaccoBase: 503.7,
          juvenileCash: 322.90128,
          nonTobaccoCash: 296.20142,
          tobaccoCash: 378.23645,
        },
        male: {
          nonTobaccoBase: 436.09,
          tobaccoBase: 539.28,
          juvenileCash: 347.4353,
          nonTobaccoCash: 327.63046,
          tobaccoCash: 405.0256,
        },
      },
      52: {
        female: {
          nonTobaccoBase: 407.59,
          tobaccoBase: 519.14,
          juvenileCash: 333.44785,
          nonTobaccoCash: 306.16904,
          tobaccoCash: 389.86121,
        },
        male: {
          nonTobaccoBase: 450.2,
          tobaccoBase: 555.29,
          juvenileCash: 358.35785,
          nonTobaccoCash: 338.2019,
          tobaccoCash: 417.06787,
        },
      },
      53: {
        female: {
          nonTobaccoBase: 421.27,
          tobaccoBase: 534.89,
          juvenileCash: 344.27975,
          nonTobaccoCash: 316.40039,
          tobaccoCash: 401.72342,
        },
        male: {
          nonTobaccoBase: 464.66,
          tobaccoBase: 571.71,
          juvenileCash: 369.59601,
          nonTobaccoCash: 349.03638,
          tobaccoCash: 429.36084,
        },
      },
      54: {
        female: {
          nonTobaccoBase: 435.3,
          tobaccoBase: 550.93,
          juvenileCash: 355.39285,
          nonTobaccoCash: 326.8819,
          tobaccoCash: 413.80624,
        },
        male: {
          nonTobaccoBase: 479.47,
          tobaccoBase: 588.33,
          juvenileCash: 381.14175,
          nonTobaccoCash: 360.14319,
          tobaccoCash: 441.90344,
        },
      },
      55: {
        female: {
          nonTobaccoBase: 449.65,
          tobaccoBase: 567.32,
          juvenileCash: 366.79715,
          nonTobaccoCash: 337.6145,
          tobaccoCash: 426.10654,
        },
        male: {
          nonTobaccoBase: 494.66,
          tobaccoBase: 605.24,
          juvenileCash: 392.99457,
          nonTobaccoCash: 371.53253,
          tobaccoCash: 454.67941,
        },
      },
      56: {
        female: {
          nonTobaccoBase: 464.34,
          tobaccoBase: 583.87,
          juvenileCash: 378.49078,
          nonTobaccoCash: 348.62009,
          tobaccoCash: 438.61118,
        },
        male: {
          nonTobaccoBase: 510.25,
          tobaccoBase: 622.39,
          juvenileCash: 405.14294,
          nonTobaccoCash: 383.22836,
          tobaccoCash: 467.66928,
        },
      },
      57: {
        female: {
          nonTobaccoBase: 479.4,
          tobaccoBase: 600.62,
          juvenileCash: 390.46677,
          nonTobaccoCash: 359.91479,
          tobaccoCash: 451.29767,
        },
        male: {
          nonTobaccoBase: 526.28,
          tobaccoBase: 639.75,
          juvenileCash: 417.58298,
          nonTobaccoCash: 395.26178,
          tobaccoCash: 480.85623,
        },
      },
      58: {
        female: {
          nonTobaccoBase: 494.87,
          tobaccoBase: 617.57,
          juvenileCash: 402.72568,
          nonTobaccoCash: 371.52203,
          tobaccoCash: 464.14581,
        },
        male: {
          nonTobaccoBase: 542.73,
          tobaccoBase: 657.29,
          juvenileCash: 430.30066,
          nonTobaccoCash: 407.64627,
          tobaccoCash: 494.21576,
        },
      },
      59: {
        female: {
          nonTobaccoBase: 510.74,
          tobaccoBase: 634.67,
          juvenileCash: 415.25751,
          nonTobaccoCash: 383.44693,
          tobaccoCash: 477.13809,
        },
        male: {
          nonTobaccoBase: 559.62,
          tobaccoBase: 675.02,
          juvenileCash: 443.28403,
          nonTobaccoCash: 420.39044,
          tobaccoCash: 507.72147,
        },
      },
      60: {
        female: {
          nonTobaccoBase: 527.03,
          tobaccoBase: 651.92,
          juvenileCash: 428.05988,
          nonTobaccoCash: 395.70197,
          tobaccoCash: 490.25955,
        },
        male: {
          nonTobaccoBase: 577.04,
          tobaccoBase: 692.84,
          juvenileCash: 456.51196,
          nonTobaccoCash: 433.48093,
          tobaccoCash: 521.33551,
        },
      },
      61: {
        female: {
          nonTobaccoBase: 543.71,
          tobaccoBase: 669.25,
          juvenileCash: 441.11481,
          nonTobaccoCash: 408.2825,
          tobaccoCash: 503.48245,
        },
        male: {
          nonTobaccoBase: 594.73,
          tobaccoBase: 710.61,
          juvenileCash: 469.97156,
          nonTobaccoCash: 446.89493,
          tobaccoCash: 535.01929,
        },
      },
      62: {
        female: {
          nonTobaccoBase: 560.77,
          tobaccoBase: 686.65,
          juvenileCash: 454.41232,
          nonTobaccoCash: 421.17337,
          tobaccoCash: 516.78247,
        },
        male: {
          nonTobaccoBase: 612.72,
          tobaccoBase: 728.28,
          juvenileCash: 483.63651,
          nonTobaccoCash: 460.5842,
          tobaccoCash: 548.72467,
        },
      },
      63: {
        female: {
          nonTobaccoBase: 578.34,
          tobaccoBase: 704.08,
          juvenileCash: 467.93381,
          nonTobaccoCash: 434.37326,
          tobaccoCash: 530.14355,
        },
        male: {
          nonTobaccoBase: 631,
          tobaccoBase: 745.86,
          juvenileCash: 497.48907,
          nonTobaccoCash: 474.53165,
          tobaccoCash: 562.41284,
        },
      },
      64: {
        female: {
          nonTobaccoBase: 596.16,
          tobaccoBase: 721.51,
          juvenileCash: 481.66885,
          nonTobaccoCash: 447.87122,
          tobaccoCash: 543.5437,
        },
        male: {
          nonTobaccoBase: 649.54,
          tobaccoBase: 763.38,
          juvenileCash: 511.50977,
          nonTobaccoCash: 488.71783,
          tobaccoCash: 576.0592,
        },
      },
      65: {
        female: {
          nonTobaccoBase: 614.33,
          tobaccoBase: 738.93,
          juvenileCash: 495.60474,
          nonTobaccoCash: 461.67551,
          tobaccoCash: 556.9693,
        },
        male: {
          nonTobaccoBase: 668.32,
          tobaccoBase: 780.78,
          juvenileCash: 525.69794,
          nonTobaccoCash: 503.14249,
          tobaccoCash: 589.63049,
        },
      },
      66: {
        female: {
          nonTobaccoBase: 632.84,
          tobaccoBase: 756.33,
          juvenileCash: 509.72678,
          nonTobaccoCash: 475.78552,
          tobaccoCash: 570.40607,
        },
        male: {
          nonTobaccoBase: 687.37,
          tobaccoBase: 798,
          juvenileCash: 540.06116,
          nonTobaccoCash: 517.80841,
          tobaccoCash: 603.10272,
        },
      },
      67: {
        female: {
          nonTobaccoBase: 651.72,
          tobaccoBase: 773.72,
          juvenileCash: 524.01904,
          nonTobaccoCash: 490.20856,
          tobaccoCash: 583.84839,
        },
        male: {
          nonTobaccoBase: 706.69,
          tobaccoBase: 815.07,
          juvenileCash: 554.6059,
          nonTobaccoCash: 532.72668,
          tobaccoCash: 616.47748,
        },
      },
      68: {
        female: {
          nonTobaccoBase: 671,
          tobaccoBase: 791.06,
          juvenileCash: 538.46472,
          nonTobaccoCash: 504.95453,
          tobaccoCash: 597.29486,
        },
        male: {
          nonTobaccoBase: 726.28,
          tobaccoBase: 832,
          juvenileCash: 569.33813,
          nonTobaccoCash: 547.89294,
          tobaccoCash: 629.76874,
        },
      },
      69: {
        female: {
          nonTobaccoBase: 690.65,
          tobaccoBase: 808.39,
          juvenileCash: 553.05621,
          nonTobaccoCash: 520.02112,
          tobaccoCash: 610.76099,
        },
        male: {
          nonTobaccoBase: 746.14,
          tobaccoBase: 848.76,
          juvenileCash: 584.25073,
          nonTobaccoCash: 563.29822,
          tobaccoCash: 642.96783,
        },
      },
      70: {
        female: {
          nonTobaccoBase: 710.62,
          tobaccoBase: 825.68,
          juvenileCash: 567.77686,
          nonTobaccoCash: 535.39014,
          tobaccoCash: 624.24249,
        },
        male: {
          nonTobaccoBase: 766.17,
          tobaccoBase: 865.29,
          juvenileCash: 599.30829,
          nonTobaccoCash: 578.91217,
          tobaccoCash: 656.04553,
        },
      },
      71: {
        female: {
          nonTobaccoBase: 730.85,
          tobaccoBase: 842.85,
          juvenileCash: 582.62427,
          nonTobaccoCash: 551.03912,
          tobaccoCash: 637.70941,
        },
        male: {
          nonTobaccoBase: 786.29,
          tobaccoBase: 881.53,
          juvenileCash: 614.46283,
          nonTobaccoCash: 594.6861,
          tobaccoCash: 668.93939,
        },
      },
      72: {
        female: {
          nonTobaccoBase: 751.31,
          tobaccoBase: 859.93,
          juvenileCash: 597.58856,
          nonTobaccoCash: 566.93311,
          tobaccoCash: 651.19177,
        },
        male: {
          nonTobaccoBase: 806.51,
          tobaccoBase: 897.38,
          juvenileCash: 629.64764,
          nonTobaccoCash: 610.56378,
          tobaccoCash: 681.57361,
        },
      },
      73: {
        female: {
          nonTobaccoBase: 771.94,
          tobaccoBase: 876.97,
          juvenileCash: 612.66632,
          nonTobaccoCash: 583.03564,
          tobaccoCash: 664.72522,
        },
        male: {
          nonTobaccoBase: 826.73,
          tobaccoBase: 912.78,
          juvenileCash: 644.7995,
          nonTobaccoCash: 626.48352,
          tobaccoCash: 693.89624,
        },
      },
      74: {
        female: {
          nonTobaccoBase: 792.65,
          tobaccoBase: 894.01,
          juvenileCash: 627.8371,
          nonTobaccoCash: 599.30988,
          tobaccoCash: 678.30585,
        },
        male: {
          nonTobaccoBase: 846.83,
          tobaccoBase: 927.9,
          juvenileCash: 659.87415,
          nonTobaccoCash: 642.39545,
          tobaccoCash: 705.88428,
        },
      },
      75: {
        female: {
          nonTobaccoBase: 813.46,
          tobaccoBase: 911.11,
          juvenileCash: 643.08124,
          nonTobaccoCash: 615.716,
          tobaccoCash: 691.92181,
        },
        male: {
          nonTobaccoBase: 866.79,
          tobaccoBase: 942.46,
          juvenileCash: 674.84198,
          nonTobaccoCash: 658.26154,
          tobaccoCash: 717.56323,
        },
      },
      76: {
        female: {
          nonTobaccoBase: 834.31,
          tobaccoBase: 928.39,
          juvenileCash: 658.36664,
          nonTobaccoCash: 632.21692,
          tobaccoCash: 705.55878,
        },
        male: {
          nonTobaccoBase: 886.54,
          tobaccoBase: 956.74,
          juvenileCash: 689.7016,
          nonTobaccoCash: 674.07037,
          tobaccoCash: 729.00262,
        },
      },
      77: {
        female: {
          nonTobaccoBase: 855.13,
          tobaccoBase: 945.46,
          juvenileCash: 673.65466,
          nonTobaccoCash: 648.7757,
          tobaccoCash: 719.1983,
        },
        male: {
          nonTobaccoBase: 906.06,
          tobaccoBase: 970.8,
          juvenileCash: 704.46582,
          nonTobaccoCash: 689.81555,
          tobaccoCash: 740.29974,
        },
      },
      78: {
        female: {
          nonTobaccoBase: 875.85,
          tobaccoBase: 962.44,
          juvenileCash: 688.91296,
          nonTobaccoCash: 665.33936,
          tobaccoCash: 732.82971,
        },
        male: {
          nonTobaccoBase: 925.48,
          tobaccoBase: 984.76,
          juvenileCash: 719.14636,
          nonTobaccoCash: 705.48755,
          tobaccoCash: 751.54694,
        },
      },
      79: {
        female: {
          nonTobaccoBase: 896.42,
          tobaccoBase: 979.32,
          juvenileCash: 704.11316,
          nonTobaccoCash: 681.83246,
          tobaccoCash: 746.44836,
        },
        male: {
          nonTobaccoBase: 944.42,
          tobaccoBase: 985,
          juvenileCash: 733.73248,
          nonTobaccoCash: 721.06104,
          tobaccoCash: 762.81512,
        },
      },
      80: {
        female: {
          nonTobaccoBase: 916.86,
          tobaccoBase: 985,
          juvenileCash: 719.21741,
          nonTobaccoCash: 698.14545,
          tobaccoCash: 759.9212,
        },
        male: {
          nonTobaccoBase: 963.16,
          tobaccoBase: 985,
          juvenileCash: 748.19037,
          nonTobaccoCash: 736.48468,
          tobaccoCash: 774.14246,
        },
      },
      81: {
        female: {
          nonTobaccoBase: 936.78,
          tobaccoBase: 985,
          juvenileCash: 734.14862,
          nonTobaccoCash: 714.17834,
          tobaccoCash: 773.03113,
        },
        male: {
          nonTobaccoBase: 981.76,
          tobaccoBase: 985,
          juvenileCash: 762.46832,
          nonTobaccoCash: 751.68713,
          tobaccoCash: 785.54663,
        },
      },
      82: {
        female: {
          nonTobaccoBase: 956.48,
          tobaccoBase: 985,
          juvenileCash: 748.81696,
          nonTobaccoCash: 729.96759,
          tobaccoCash: 785.65283,
        },
        male: {
          nonTobaccoBase: 985,
          tobaccoBase: 985,
          juvenileCash: 776.54004,
          nonTobaccoCash: 766.6167,
          tobaccoCash: 797.08575,
        },
      },
      83: {
        female: {
          nonTobaccoBase: 976.1,
          tobaccoBase: 985,
          juvenileCash: 763.17157,
          nonTobaccoCash: 745.60089,
          tobaccoCash: 797.70074,
        },
        male: {
          nonTobaccoBase: 985,
          tobaccoBase: 985,
          juvenileCash: 790.31,
          nonTobaccoCash: 781.2171,
          tobaccoCash: 808.57751,
        },
      },
      84: {
        female: {
          nonTobaccoBase: 985,
          tobaccoBase: 985,
          juvenileCash: 777.14148,
          nonTobaccoCash: 761.02838,
          tobaccoCash: 809.18732,
        },
        male: {
          nonTobaccoBase: 985,
          tobaccoBase: 985,
          juvenileCash: 803.64148,
          nonTobaccoCash: 795.40454,
          tobaccoCash: 819.73956,
        },
      },
      85: {
        female: {
          nonTobaccoBase: 985,
          tobaccoBase: 985,
          juvenileCash: 790.64655,
          nonTobaccoCash: 775.86499,
          tobaccoCash: 820.33661,
        },
        male: {
          nonTobaccoBase: 985,
          tobaccoBase: 985,
          juvenileCash: 816.44421,
          nonTobaccoCash: 809.07971,
          tobaccoCash: 830.49146,
        },
      },
      86: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 803.59589,
          nonTobaccoCash: 790.01019,
          tobaccoCash: 830.93243,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 828.61365,
          nonTobaccoCash: 822.13397,
          tobaccoCash: 840.73322,
        },
      },
      87: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 815.94464,
          nonTobaccoCash: 803.6701,
          tobaccoCash: 840.76392,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 840.04535,
          nonTobaccoCash: 834.44971,
          tobaccoCash: 850.36548,
        },
      },
      88: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 827.68427,
          nonTobaccoCash: 816.75714,
          tobaccoCash: 850.01245,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 850.64203,
          nonTobaccoCash: 845.90918,
          tobaccoCash: 859.29486,
        },
      },
      89: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 838.78821,
          nonTobaccoCash: 829.18298,
          tobaccoCash: 858.64264,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 860.35315,
          nonTobaccoCash: 856.44,
          tobaccoCash: 867.47485,
        },
      },
      90: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 849.24329,
          nonTobaccoCash: 840.9267,
          tobaccoCash: 866.65216,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 869.17511,
          nonTobaccoCash: 866.01984,
          tobaccoCash: 874.90924,
        },
      },
      91: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 859.08478,
          nonTobaccoCash: 852.00989,
          tobaccoCash: 874.09399,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 877.1424,
          nonTobaccoCash: 874.67102,
          tobaccoCash: 881.63824,
        },
      },
      92: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 868.3576,
          nonTobaccoCash: 862.46686,
          tobaccoCash: 881.03204,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 884.349,
          nonTobaccoCash: 882.47821,
          tobaccoCash: 887.7605,
        },
      },
      93: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 877.08551,
          nonTobaccoCash: 872.31403,
          tobaccoCash: 887.50519,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 890.91425,
          nonTobaccoCash: 889.5567,
          tobaccoCash: 893.39954,
        },
      },
      94: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 885.39471,
          nonTobaccoCash: 881.66235,
          tobaccoCash: 893.66583,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 897.01971,
          nonTobaccoCash: 896.08533,
          tobaccoCash: 898.73676,
        },
      },
      95: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 893.44824,
          nonTobaccoCash: 890.65875,
          tobaccoCash: 899.71954,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 902.97925,
          nonTobaccoCash: 902.37878,
          tobaccoCash: 904.08777,
        },
      },
      96: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 901.14764,
          nonTobaccoCash: 899.19757,
          tobaccoCash: 905.59308,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 908.86499,
          nonTobaccoCash: 908.51074,
          tobaccoCash: 909.51959,
        },
      },
      97: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 908.30621,
          nonTobaccoCash: 907.08759,
          tobaccoCash: 911.1192,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 914.48401,
          nonTobaccoCash: 914.30267,
          tobaccoCash: 914.8186,
        },
      },
      98: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 914.87982,
          nonTobaccoCash: 914.26453,
          tobaccoCash: 916.31689,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 919.78839,
          nonTobaccoCash: 919.71545,
          tobaccoCash: 919.92206,
        },
      },
      99: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 920.82623,
          nonTobaccoCash: 920.64337,
          tobaccoCash: 921.25696,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 924.69501,
          nonTobaccoCash: 924.6778,
          tobaccoCash: 924.72607,
        },
      },
      100: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 926.10736,
          nonTobaccoCash: 926.10736,
          tobaccoCash: 926.10736,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 929.12872,
          nonTobaccoCash: 929.12872,
          tobaccoCash: 929.12872,
        },
      },
      101: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 930.77118,
          nonTobaccoCash: 930.77118,
          tobaccoCash: 930.77118,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 933.10364,
          nonTobaccoCash: 933.10364,
          tobaccoCash: 933.10364,
        },
      },
      102: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 934.93567,
          nonTobaccoCash: 934.93567,
          tobaccoCash: 934.93567,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 936.71466,
          nonTobaccoCash: 936.71466,
          tobaccoCash: 936.71466,
        },
      },
      103: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 938.64813,
          nonTobaccoCash: 938.64813,
          tobaccoCash: 938.64813,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 939.98779,
          nonTobaccoCash: 939.98779,
          tobaccoCash: 939.98779,
        },
      },
      104: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 941.98071,
          nonTobaccoCash: 941.98071,
          tobaccoCash: 941.98071,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 942.98059,
          nonTobaccoCash: 942.98059,
          tobaccoCash: 942.98059,
        },
      },
      105: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 945.05615,
          nonTobaccoCash: 945.05615,
          tobaccoCash: 945.05615,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 945.80859,
          nonTobaccoCash: 945.80859,
          tobaccoCash: 945.80859,
        },
      },
      106: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 948.10608,
          nonTobaccoCash: 948.10608,
          tobaccoCash: 948.10608,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 948.71448,
          nonTobaccoCash: 948.71448,
          tobaccoCash: 948.71448,
        },
      },
      107: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 951.23431,
          nonTobaccoCash: 951.23431,
          tobaccoCash: 951.23431,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 951.77814,
          nonTobaccoCash: 951.77814,
          tobaccoCash: 951.77814,
        },
      },
      108: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 954.23987,
          nonTobaccoCash: 954.23987,
          tobaccoCash: 954.23987,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 954.72217,
          nonTobaccoCash: 954.72217,
          tobaccoCash: 954.72217,
        },
      },
      109: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 957.12561,
          nonTobaccoCash: 957.12561,
          tobaccoCash: 957.12561,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 957.54999,
          nonTobaccoCash: 957.54999,
          tobaccoCash: 957.54999,
        },
      },
      110: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 959.89484,
          nonTobaccoCash: 959.89484,
          tobaccoCash: 959.89484,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 960.26465,
          nonTobaccoCash: 960.26465,
          tobaccoCash: 960.26465,
        },
      },
      111: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 962.55017,
          nonTobaccoCash: 962.55017,
          tobaccoCash: 962.55017,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 962.8692,
          nonTobaccoCash: 962.8692,
          tobaccoCash: 962.8692,
        },
      },
      112: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 965.09503,
          nonTobaccoCash: 965.09503,
          tobaccoCash: 965.09503,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 965.36658,
          nonTobaccoCash: 965.36658,
          tobaccoCash: 965.36658,
        },
      },
      113: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 967.53217,
          nonTobaccoCash: 967.53217,
          tobaccoCash: 967.53217,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 967.75964,
          nonTobaccoCash: 967.75964,
          tobaccoCash: 967.75964,
        },
      },
      114: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 969.86462,
          nonTobaccoCash: 969.86462,
          tobaccoCash: 969.86462,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 970.05127,
          nonTobaccoCash: 970.05127,
          tobaccoCash: 970.05127,
        },
      },
      115: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 972.09564,
          nonTobaccoCash: 972.09564,
          tobaccoCash: 972.09564,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 972.24426,
          nonTobaccoCash: 972.24426,
          tobaccoCash: 972.24426,
        },
      },
      116: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 974.22803,
          nonTobaccoCash: 974.22803,
          tobaccoCash: 974.22803,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 974.34119,
          nonTobaccoCash: 974.34119,
          tobaccoCash: 974.34119,
        },
      },
      117: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 976.26392,
          nonTobaccoCash: 976.26392,
          tobaccoCash: 976.26392,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 976.34491,
          nonTobaccoCash: 976.34491,
          tobaccoCash: 976.34491,
        },
      },
      118: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 978.20587,
          nonTobaccoCash: 978.20587,
          tobaccoCash: 978.20587,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 978.2572,
          nonTobaccoCash: 978.2572,
          tobaccoCash: 978.2572,
        },
      },
      119: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 980.05627,
          nonTobaccoCash: 980.05627,
          tobaccoCash: 980.05627,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 980.08081,
          nonTobaccoCash: 980.08081,
          tobaccoCash: 980.08081,
        },
      },
      120: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 981.81683,
          nonTobaccoCash: 981.81683,
          tobaccoCash: 981.81683,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 981.81683,
          nonTobaccoCash: 981.81683,
          tobaccoCash: 981.81683,
        },
      },
      121: {
        female: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 1000,
          nonTobaccoCash: 1000,
          tobaccoCash: 1000,
        },
        male: {
          nonTobaccoBase: null,
          tobaccoBase: null,
          juvenileCash: 1000,
          nonTobaccoCash: 1000,
          tobaccoCash: 1000,
        },
      },
    },
  };

  // Basically an estimate on remaining years before insured is deceased
  private yearsToAccrueInterest: number = 20;
  // Before-tax return on taxable product (%)
  private returnOnTaxable: string = '2.0';
  // Before-tax return on tax-deferred product (%)
  private returnOnTaxDeferred: string = '2.0';
  // Marginal tax bracket (%)
  private marginalTaxBracket: string = '25.0';

  private tableData: any[] = [];

  private optionsHeight: string = '0px';

  // Validation Values
  private maxPremium: number = 2000000;
  private maxAge: number = 80;
  private minFaceAmountAgeCutoff: number = 55;
  private minUnderCutoff: number = 10000;
  private minOverCutoff: number = 5000;

  private singlePremiumError() {
    if (this.product.premium < this.getMinimumPremium()) {
      return `Minimum payment of ${this.formatAsCurrency(Math.ceil(this.getMinimumPremium())).slice(0, -3)}`;
    } else if (this.product.premium > 2000000) {
      return 'Maximum payment of $2,000,000';
    } else {
      return null;
    }
  }

  // Calculate the face amount of the policy (the death benefit the insured receives out of the gate)
  private getFaceAmount() {
    const tobacco = this.user.age > 17 ? this.user.tobacco : true;
    const validUser = (this.user.age || this.user.age === 0) && this.user.gender && tobacco && this.user.age <= this.maxAge;
    const validProduct = this.product.premium && this.product.premium <= this.maxPremium;

    if (validUser && validProduct) {
      let base;
      if (this.user.age > 17) {
        base =
          this.user.tobacco === 'tobacco'
            ? this.premiumPerUnit.age[this.user.age][this.user.gender].tobaccoBase
            : this.premiumPerUnit.age[this.user.age][this.user.gender].nonTobaccoBase;
      } else {
        base = this.premiumPerUnit.age[this.user.age][this.user.gender].nonTobaccoBase;
      }

      this.product.faceAmount = Math.floor((((this.product.premium + 0.00499999999) - 75) / base) * 1000);
      this.buildTableData();
    } else {
      this.product.faceAmount = 0;
    }
  }

  private getTableVisibility() {
    // Product availability rules. Age 0 - 54 have a $10,000 min. face amount. Age 55 - 80 have $5,000 min face amount.
    const underCutoffVisible = this.product.faceAmount && this.user.age < this.minFaceAmountAgeCutoff && this.product.faceAmount > this.minUnderCutoff;
    const overCutoffVisible =
      this.product.faceAmount && this.user.age >= this.minFaceAmountAgeCutoff && this.user.age <= this.maxAge && this.product.faceAmount > this.minOverCutoff;

    if (underCutoffVisible || overCutoffVisible) {
      return true;
    } else {
      return false;
    }
  }

  // Return the minimum premium based on age group and premium per unit.
  private getMinimumPremium() {
    if (this.user.age && this.user.gender && this.product.premium) {
      const base =
        this.user.tobacco === 'tobacco'
          ? this.premiumPerUnit.age[this.user.age][this.user.gender].tobaccoBase
          : this.premiumPerUnit.age[this.user.age][this.user.gender].nonTobaccoBase;
      if (this.user.age < this.minFaceAmountAgeCutoff) {
        return (this.minUnderCutoff * base) / 1000 + 75;
      } else if (this.user.age >= this.minFaceAmountAgeCutoff && this.user.age <= this.maxAge) {
        return (this.minOverCutoff * base) / 1000 + 75;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  // Construct the data for the table in the this.tableData object
  private buildTableData() {
    for (let i = 0; i < this.yearsToAccrueInterest; i++) {
      const previousYearTaxable = i ? this.tableData[i - 1].fullyTaxable : this.product.premium;
      const previousYearBeforeTax = i ? this.tableData[i - 1].taxDeferredBeforeTax : this.product.premium;

      const tableRow = {
        year: i + 1,
        faceAmount: this.formatAsCurrency(this.product.faceAmount),
        fullyTaxable: this.getFullyTaxable(previousYearTaxable),
        taxDeferredBeforeTax: this.getTaxDeferredBeforeTax(previousYearBeforeTax),
        taxDeferredAfterTax: this.getTaxDeferredAfterTax(previousYearBeforeTax),
        guaranteedCashValue: this.formatAsCurrency(Math.round(this.getGuaranteedCashValue(this.product.faceAmount, i))),
        guaranteedDeathBenefit: this.formatAsCurrency(this.product.faceAmount),
      };

      this.tableData.splice(i, 1, tableRow);
    }
  }

  // Provide the Guaranteed Cash Value for the current year
  private getGuaranteedCashValue(faceValue: number, ageOffset: number) {
    const age = Number(this.user.age) + ageOffset + 1;
    let cashVal;
    if (this.user.age > 17) {
      cashVal =
        this.user.tobacco === 'tobacco'
          ? this.premiumPerUnit.age[age][this.user.gender].tobaccoCash
          : this.premiumPerUnit.age[age][this.user.gender].nonTobaccoCash;
    } else {
      cashVal = this.premiumPerUnit.age[age][this.user.gender].juvenileCash;
    }

    return (cashVal * faceValue) / 1000;
  }

  // Provide the fully taxable account value from the previous year and get this year's fully taxable account value
  private getFullyTaxable(previousYearValue: number) {
    const interest = previousYearValue * (Number(this.returnOnTaxable) / 100);
    const fullyTaxable = previousYearValue + (interest - interest * (Number(this.marginalTaxBracket) / 100));

    return fullyTaxable;
  }

  // Proide the previous year's before tax account value and get this year's before tax account value
  private getTaxDeferredBeforeTax(previousYearBeforeTax: number) {
    const interest = previousYearBeforeTax * (Number(this.returnOnTaxDeferred) / 100);
    const beforeTax = previousYearBeforeTax + interest;

    return beforeTax;
  }

  // Provide the previous year's before tax account value and get this year's after tax value
  private getTaxDeferredAfterTax(previousYearBeforeTax: number) {
    const interest = previousYearBeforeTax * (Number(this.returnOnTaxDeferred) / 100);
    const valueToDate = previousYearBeforeTax + interest;

    const interestToDate = valueToDate - this.product.premium;
    const taxes = interestToDate * (Number(this.marginalTaxBracket) / 100);

    const valueAfterTaxes = valueToDate - taxes;

    return valueAfterTaxes;
  }

  private toggleAdvancedOptions() {
    this.showAdvancedOptions = !this.showAdvancedOptions;
  }

  private formatAsDecimal(value: string) {
    return value.replace(/[^0-9.]/g, '');
  }

  // TODO: Move this into a mixin to be used in here and Input.vue and anywhere else that uses it
  private formatAsCurrency(value: string | number) {
    if (value) {
      return Number(String(value).replace(/[^0-9.]/g, '')).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    } else {
      return '';
    }
  }

  private mounted() {
    if (!this.yearsToAccrueInterest) this.yearsToAccrueInterest = 20;
    if (!this.returnOnTaxable) this.returnOnTaxable = '2.0';
    if (!this.returnOnTaxDeferred) this.returnOnTaxDeferred = '2.0';
    if (!this.marginalTaxBracket) this.marginalTaxBracket = '25';

    // Set the height of the advanced options section if it's open
    const advancedOptionsElem = document.querySelector('#advanced-options-height-reference') as HTMLElement;
    if (advancedOptionsElem) this.optionsHeight = `${String(advancedOptionsElem.offsetHeight)}px`;
  }
}
